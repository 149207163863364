<template>
  <base-page
    :title="$t('customerManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.customerList"
      :defaultParams="params"
      :columns="columns"
      v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 联系地址 -->
      <template v-slot:address="{ row }">
        <!-- lang:地址 -->
        {{
          row.address
            ? $util.getTreeNames(row.address, 'value') +
              (row.addressDetail ? '/' + row.addressDetail : '')
            : row.addressDetail || ''
        }}
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <dialog-import
      v-if="showDialogImport"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.customerImportCustomer"
      :template="importTemplate"
      @cancel="showDialogImport = false"
      @confirm="importConfirm"
    />
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    const lang = this.$util.getLocalStorage('lang')
    return {
      importTemplate: `/template/${lang}/customer.xlsx`,
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('customerManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-cascader',
          label: this.$t('companyManage.address'),
          prop: 'addressId',
          span: 6,
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      params: {
        needFullAddress: 'YES',
      },
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:客户名称
        {
          label: this.$t('customerManage.name'),
          prop: 'name',
        },
        // lang:部门
        {
          label: this.$t('customerManage.department'),
          prop: 'department',
        },
        // lang:负责人
        {
          label: this.$t('customerManage.director'),
          prop: 'director',
        },
        // lang:联系方式
        {
          label: this.$t('customerManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:联系地址
        {
          label: this.$t('customerManage.address'),
          prop: 'address',
        },
      ],
      // 表单页
      showDetail: false,
      // 导入
      showDialogImport: false,
    }
  },
  mounted() {
    const language = this.$util.getLocalStorage('lang')
    this.dictFindTree('ADDRESS', language)
  },
  methods: {
    // 查询字典
    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        this.config[1].tagProps.options = data || []
      })
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if (formData.addressId) formData.addressId = formData.addressId.pop()
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && method !== 'import' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        case 'import':
          this.showDialogImport = true
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customerDelete,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
    // 导入成功回掉
    importConfirm() {
      this.showDialogImport = false
      this.search()
    },
  },
}
</script>