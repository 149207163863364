<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:客户名称
        {
          label: this.$t('customerManage.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 36,
            showWordLimit: true,
          },
        },
        // lang:部门
        {
          label: this.$t('customerManage.department'),
          prop: 'department',
          rules: {
            noNull: true,
          },
        },
        // lang:负责人
        {
          label: this.$t('customerManage.director'),
          prop: 'director',
          rules: {
            noNull: true,
          },
        },
        // lang:联系方式
        {
          label: this.$t('customerManage.contactsInformation'),
          prop: 'contactsInformation',
          rules: {
            noNull: true,
            type: 'mobileOrPhone',
          },
        },
        // lang:联系地址
        {
          tag: 'el-cascader',
          label: this.$t('customerManage.address'),
          prop: 'address',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'uid',
            },
          },
        },
        // lang:详细地址
        {
          label: this.$t('customerManage.addressDetail'),
          prop: 'addressDetail',
          rules: {
            noNull: true,
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        const language = this.$util.getLocalStorage('lang')
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          // 地址处理
          this.formData.address = this.$util.getTreeIds(this.tableRow.address)
          // 语言处理
          this.dictFindTree(
            'ADDRESS',
            this.tableRow.address ? this.tableRow.address.language : language
          )
        } else {
          this.dictFindTree('ADDRESS', language)
        }
      })
    },
    // 查询字典
    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        console.log('data', data)
        this.config[4].tagProps.options = data || []
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      // 处理地址
      formData.address = { uid: formData.address.pop() }
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customerAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customerUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>
